<template>
	<div>
		<panel :variant="panelVariant" bodyClass="p-0" hideExpand="true" hideReload="true" hideCollapse="true" hideRemove="true" :fixedHeight="550" :isHoverButton="false">
			<template slot="header">
				<span class="panel-title"><trans>메뉴</trans></span>
			</template>

			<template slot="button">
				<button class="btn btn-xs btn-lime m-r-5" @click="openNewMenuModal()" v-b-tooltip.hover :title="$t('메뉴 추가')"><i class="fa fa-plus"></i></button>
				<button class="btn btn-xs btn-success" @click="onRefresh()" v-b-tooltip.hover :title="$t('새로고침')"><i class="fa fa-redo"></i></button>
			</template>
			<div class="col-md-12 xe-groupTab">
				<div class="col-lg-12" v-if="ui.isShow">
					<tree ref="tree" :data="menuTree" :options="{ propertyNames: { text: 'title' }, dnd: true }" @node:dragging:finish="dragFinish" @node:selected="onNodeSelected">
						<template slot-scope="{ node }">
							<div class="row width-full xe-hover-box">
								<div class="col-lg-1" v-if="node.text !== 'ROOT'">
									<xe-check-box3
										:checked="isCheckNode(node)"
										:child="childCheckedNode(node)"
										@click="onCheckNode(node)"
										@need-checked="needSaveGroupMenuRef(node)"
										@need-unchecked="needRemoveGroupMenuRef(node)"
									/>
								</div>
								<div class="col-lg-10">
									<label :style="{ display: 'flex' }">
										<i v-if="node.data.icon && node.data.icon.includes('material')" :class="'material-icons materialIconForm'">
											{{ node.data.icon.split(" ")[1] }}
										</i>
										<i v-else v-bind:class="node.data.icon" class="originIconForm"></i>
										<span :style="{ 'text-decoration': node.data.isUse == 0 ? 'line-through' : 'none' }"> {{ $t(node.text) }} </span>
									</label>
								</div>
								<div class="col-lg-1" v-if="node.text !== 'ROOT'">
									<!-- 클릭시 트리접기가 실행되서 style로 보정함. -->
									<div class="xe-hover-button m-auto" style="min-width:30px;min-height:30px;" @click.stop="openMenuModal(node)">
										<i class="fa fa-edit pull-right p-t-10 p-r-5" v-b-tooltip.hover :title="$t('메뉴 수정')"></i>
									</div>
								</div>
							</div>
						</template>
					</tree>
				</div>
			</div>
		</panel>

		<b-modal ref="newMenuModal" :title="$t('새 메뉴')" hide-footer>
			<menu-detail
				:changeMenu="{ displayOrder: 225, isUse: 1, resourceType: '' }"
				:parentMenu="parentMenu"
				:targetClient="targetClient"
				:groupMenus="groupMenus"
				:curGroupIdx="curGroupIdx"
				@save-done="onNewMenuSaveDone"
			/>
		</b-modal>

		<b-modal ref="menuModal" :title="$t('메뉴 상세')" hide-footer>
			<menu-detail :changeMenu="changeMenu" :parentMenu="parentMenu" :targetClient="targetClient" :groupMenus="groupMenus" :curGroupIdx="curGroupIdx" @save-done="onMenuSaveDone" />
		</b-modal>
	</div>
</template>

<style scoped>
	.materialIconForm {
		align-self: center;
		font-size: 14px;
		width: 14px;
		height: 14px;
		text-align: center;
		margin-right: 5px;
	}

	.originIconForm {
		align-self: center;
		width: 14px;
		height: 14px;
		text-align: center;
		margin-right: 5px;
	}
</style>

<script>
	import LiquorTree from "liquor-tree";
	import backEndApi from "../../../../api/backEndApi";
	import _routes from "../../../../config/PageRoutes.vue";
	import xeCheckBox3 from "../../../../plugins/checkBox3/CheckBox3.vue";
	import MenuDetail from "./MenuDetail.vue";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {
			[LiquorTree.name]: LiquorTree,
			xeCheckBox3,
			MenuDetail,
		},
		props: ["curGroupIdx"],
		data() {
			return {
				ui: { isShow: false },
				menuTree: [],

				groupMenus: [], // 그룹내 메뉴권한 할당
				changeMenu: {}, // 현재 트리에서 선택된 메뉴 데이터 (노드아님)
				parentMenu: {},
				isMobileMenu: false,
			};
		},
		watch: {
			curGroupIdx: function() {
				this.onRefresh();
			},
			isMobileMenu: function() {
				this.onRefresh();
			},
		},
		computed: {
			routePaths() {
				return _routes.map((v) => (v.path.indexOf(":") > -1 ? v.path.substring(0, v.path.indexOf(":")) : v.path)); // ":" 이후 문자열 삭제..
			},
			// 그룹 포함여부
			isCheckNode() {
				return (node) => {
					return !this.isEmpty(this.groupMenus.find((v) => v.menuIdx == node.data.menuIdx));
				};
			},
			childCheckedNode() {
				return (node) => {
					let total = 0;
					let checked = 0;

					node.children.forTree((cur) => {
						if (this.groupMenus.find((v) => v.menuIdx == cur.data.menuIdx)) {
							++checked;
						}
						++total;
					});

					return { total, checked };
				};
			},
			// 대상 디바이스가 Mobile/PC 인지 확인
			targetClient() {
				return this.isMobileMenu ? "Mobile" : "PC";
			},
		},
		methods: {
			onRefresh() {
				this.searchGroupMenu();
				this.searchMenu();
			},
			searchMenu() {
				let that = this;

				this.menuTree.clear();

				backEndApi.menu.searchMenuTree().then(({ data }) => {
					console.log(data);
					if (this.$err(data)) return;

					function initTreeNode(node) {
						node.data = Object.assign({}, node);
						node.state = { expanded: false }; //초기화

						// 메뉴가 추가, 수정, 삭제시 선택 유지
						if (that.changeMenu.menuIdx === node.data.menuIdx) {
							node.state.expanded = true;
							node.state.selected = true;

							// 변경된 데이터 업데이트
							that.changeMenu = Object.assign(that.changeMenu, node.data);
						}
						if (that.changeMenu.parentMenuIdx === node.data.menuIdx) {
							node.state.expanded = true;
						}

						if (node.children) node.children.forEach((child) => initTreeNode(child));
					}

					let menus = data;
					menus.forEach((v) => initTreeNode(v));
					menus = menus.filter((v) => v.targetClient === this.targetClient); // 메뉴 조회시 해당 타입에 맞는 디바이스만 조회 함

					// 최상 루트생성
					let root = {
						title: `${this.$store.getters.master.desc} - ${this.targetClient}` || "ROOT",
						displayOrder: 1,
						icon: "fa fa-sitemap",
						isUse: 1,
						menuIdx: null,
						parentMenuIdx: null,
						path: "",
						state: { expanded: true, selected: true },
						children: menus,
					};

					// 트리에 메뉴 바인딩
					this.menuTree.push(root);

					this.blink(this.ui);
				});
			},
			// 그룹에 포함된 메뉴 조회
			searchGroupMenu() {
				this.groupMenus.clear();

				return new Promise((resolve, reject) => {
					if (this.isEmpty(this.curGroupIdx)) reject();

					backEndApi.group.searchGroupMenu(this.curGroupIdx).then(({ data }) => {
						if (this.$err(data)) return;

						this.groupMenus.range(data);
						resolve({ result: true });
					});
				});
			},
			// 노드 선택
			onNodeSelected(node) {
				if (node.data.menuIdx == null) return;
				if (node.children) node.states.expanded = !node.states.expanded;
				this.changeMenu = node.data;
				this.parentMenu = node.data;

				//this.$emit("menu-select", this.changeMenu);
			},
			onCheckNode(node) {
				if (this.isEmpty(this.groupMenus.find((v) => v.menuIdx == node.data.menuIdx))) {
					// 그룹에 할당
					this.insertGroupMenu(node);
				} else {
					// 그룹에서 삭제
					this.alertConfirmWarning(popupMessages.PERMISSION_GROUP_MENU_DELETE_POPUP_MESSAGE).then((result) => {
						if (!result.value) return;

						// TODO: super 계정에 권한할당이 온전한지 확인하는 코드가 존재하지 않음.. - ref table 조회시 super는 자동권한생성??

						let arr = [node]; // 자기자신

						if (node.children) {
							// 자식노드 추가
							node.children.forTree((child) => {
								arr.push(child);
							});
						}

						// TODO: 트랜잭션 - 한방에 자식까지 지워야 함....
						this.deleteGroupMenu(arr).then(() => {
							this.searchGroupMenu().then(() => {
								// 자기자신의 부모노드가 모든 자식이 권한이 제거되었을때, 그 부모노드의 권한을 제거한다.

								// 부모가 있고 부모노드에 자식이 있을때
								if (node.data.parentMenuIdx && node.parent.children) {
									// 자신의 부모노드의 자식이 그룹에 포함되어있는지 판단
									let childs = node.parent.children
										.map((v) => {
											return this.groupMenus.find((groupMenu) => groupMenu.menuIdx == v.data.menuIdx);
										})
										.filter((v) => v);

									// 자식전체가 그룹에 포함되어있지않으면 자기자신도 그룹에서 삭제한다.
									if (childs.length < 1) {
										this.deleteGroupMenu([node.parent]).then(() => {
											this.searchGroupMenu();
										});
									}
								}
							});
						});
					});
				}
			},
			insertGroupMenu(node) {
				let arr = [];

				// 자식노드의 메뉴정보를 모은다.
				if (node.children) {
					arr.range(node.children.mapTree((v) => v));
				}

				// 부모노드의 메뉴정보를 모은다. 부모노드는 상위로 올라가면서 찾는다.
				let cur = node; // 자기자신
				while (!this.isEmpty(cur) && !this.isEmpty(cur.data.menuIdx)) {
					arr.push(cur);
					cur = cur.parent; // 부모노드
				}

				let nodes = arr
					.map((node) => {
						if (node.data && node.data.menuIdx) {
							return { groupIdx: this.curGroupIdx, menuIdx: node.data.menuIdx, text: node.data.title };
						}
					})
					.filter((v) => v);

				if (nodes.length < 1) return;

				this.alertQuestion(popupMessages.PERMISSION_GROUP_MENU_ALLOCATION_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.group.insertGroupMenu(nodes).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.PERMISSION_COMMON_REGIST_SUCCESS_POPUP_MESSAGE);
						this.searchGroupMenu();
						this.$store.dispatch("UPDATE_MENUS");
						this.$nextTick(this.onRefresh());
					});
				});
			},
			deleteGroupMenu(nodes) {
				// TODO : 왜 프로미스를 반환하나???
				return new Promise((resolve, reject) => {
					let arr = nodes
						.map((node) => {
							if (node.data && node.data.menuIdx) {
								return { groupIdx: this.curGroupIdx, menuIdx: node.data.menuIdx, text: node.data.title };
							}
						})
						.filter((v) => v);

					if (arr.length < 1) return;

					backEndApi.group
						.deleteGroupMenu(arr)
						.then(({ data }) => {
							if (this.$err(data)) return;

							this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
							this.$store.dispatch("UPDATE_MENUS");
							resolve({ result: true });
						})
						.catch((err) => {
							reject(err);
						});
				});
			},
			// 메뉴 모달
			openNewMenuModal() {
				this.$refs.newMenuModal.show();
			},
			onNewMenuSaveDone() {
				this.$refs.newMenuModal.hide();
				this.$nextTick(this.onRefresh());
			},
			openMenuModal(node) {
				this.changeMenu = Object.assign(this.changeMenu, node.data);
				this.$refs.menuModal.show();
			},
			onMenuSaveDone() {
				this.$refs.menuModal.hide();
				this.$nextTick(this.onRefresh());
			},
			dragFinish(node, destinationNode) {
				setTimeout(this._DragFinish, 200, node, destinationNode);
			},
			_DragFinish(node, destinationNode) {
				try {
					let pNode = {};

					// 드래그 대상노드가 부모노드가 있을경우 pNode를 tree에서 선택된 노드의 부모노드로 설정. 없을경우에는 pNode를 드래그 대상노드로 설정.
					if (this.isEmpty(destinationNode.parent)) {
						// 최상위 노드인 경우...
						pNode = destinationNode;
					} else {
						// 최상위 노드가 아닌 경우...
						if (destinationNode.children.length !== 0) {
							// 최상위 노드가 아니고, 자식이 있는 경우...
							pNode = destinationNode;

							if (!this.isEmpty(pNode.data.path)) {
								// pNode에 path가 있으면, path가 무시되어야 하므로, alert을 띄워 경고한다.
								this.alertQuestion(popupMessages.PERMISSION_GROUP_MENU_PATH_WARNING_POPUP_MESSAGE).then((result) => {
									if (!result.value) this.searchGroupMenu();
								});
							}
						} else {
							// 최상위 노드가 아니고, 자식이 없는 경우...
							pNode = destinationNode.tree.selectedNodes[0].parent;
						}
					}
				} catch (err) {
					console.error("MenuMgmt _DragFinish", err);
				}

				this.$nextTick(this.reorderTree);
			},
			reorderTree() {
				this.$refs.tree.model.forTree((node, idx) => {
					node.data.displayOrder = idx + 1;
					if (node.parent) node.data.parentMenuIdx = node.parent.data.menuIdx;
					if (this.changeMenu.menuIdx == node.data.menuIdx) this.changeMenu = node.data;
				});

				this.$nextTick(this.updateMenuAll);
			},
			// 메뉴를 하나씩 읽어서 드래그한 메뉴순서를 저장
			updateMenuAll() {
				let menus = this.$refs.tree.model.mapTree((node) => {
					if (node.data.menuIdx) return node.data;
				});

				if (menus.length < 1) return;

				backEndApi.menu.upsertMenus(menus).then(({ data }) => {
					if (this.$err(data)) return;

					this.$store.dispatch("UPDATE_MENUS");

					this.searchGroupMenu();
				});
			},
			// 데이터 이상 경고임.. 삭제해도 됨..
			needSaveGroupMenuRef(node) {
				// 체크박스내에서 자식메뉴가 그룹에 포함되어 있는데, 부모노드가 포함되어 있지 않은경우
				console.log("Need Save - GroupMenu Ref", node.text);
			},
			needRemoveGroupMenuRef(node) {
				// 체크박스내에서 자식메뉴가 그룹에 없는데, 부모노드가 포함되어 있는경우
				console.log("Need Remove - GroupMenu Ref", node.text);
			},
		},
	};
</script>
