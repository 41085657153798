import { render, staticRenderFns } from "./MenuTree.vue?vue&type=template&id=78158132&scoped=true&"
import script from "./MenuTree.vue?vue&type=script&lang=js&"
export * from "./MenuTree.vue?vue&type=script&lang=js&"
import style0 from "./MenuTree.vue?vue&type=style&index=0&id=78158132&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78158132",
  null
  
)

export default component.exports